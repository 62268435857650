const groupLockedBy = (group, reason) => {
  switch (reason) {
    case 'workflows':
      return group.$workflowsCount > 0 && !group.isRemote;
    case 'shared':
      return group.isRemote;
    default:
      return (group.$workflowsCount > 0 || group.isRemote);
  }
}

const isRegexpInMaintenance = regexps =>
  Object.entries(regexps).some(([_key, list]) =>
    list.some(item => item.status === 'maintenance'));

// avoid a pages without an angular app
if (window.gon.app) {
  let schema = window.app.schema;
  let utils = schema.utils;

  schema.extendMixins({
    alarm_sectors: {
      type: 'alarm'
    },

    building_sectors: {
      type: 'building'
    },

    contact_lists: {
      isLocked() {
        return this.$workflowsCount > 0;
      }
    },

    oncall_groups: {
      isLockedBy(reason) {
        return groupLockedBy(this, reason)
      }
    },

    room_sectors: {
      type: 'room'
    },

    user_groups: {
      isLockedBy(reason) {
        return groupLockedBy(this, reason)
      }
    },

    task_lists: {
      isLocked() {
        return this.$workflowsCount > 0;
      }
    },

    workflows: {
      isCopiable() {
        return this.$alarmType.active;
      }
    }
  });

  // it transforms time from a location zone into a client zone
  // ruby utc offset is positive in seconds
  // js utc offset is negative in minutes
  const zoneDiff = (-(new Date().getTimezoneOffset() * 60) - gon.app.timeOffset) * 1000;
  const shiftLocationZone = value => (value === null) ? null : value - zoneDiff;
  const toMinutes = o => {
    if (!o) return 0;
    return o.hh * 60 + o.mm + (o.nd ? (24 * 60) : 0);
  }

  const normalizeSerialPrinterRegexps = item => {
    if (item.type !== 'serial_printer') return;

    if (Array.isArray(item.regexps)) {
      item.regexps = item.regexps.reduce((acc, item) => {
        acc[item.alarm_type] = item.regexps;
        return acc;
      }, {});
    }
  }

  schema.extendProcesses({
    espa_routes(espaRoute) {
      var parts = [espaRoute.fireSystem];
      if (espaRoute.fireDetectorGroup) {
        parts.push(espaRoute.fireDetectorGroup);
        if (espaRoute.fireDetector) parts.push(espaRoute.fireDetector);
      }
      espaRoute.$key = parts.join('-');
    },
    external_services(item) {
      item.id = item.type + '-' + item.name;
      normalizeSerialPrinterRegexps(item);
      if (item.type === 'espa') {
        item.$inMaintenance = isRegexpInMaintenance(item.regexps);
      } else if (item.type === 'transducer') {
        item.$inMaintenance = item.handlers.some(handler => handler.type === 'espa' && isRegexpInMaintenance(handler.regexps));
        item.handlers.forEach(normalizeSerialPrinterRegexps);
      } else {
        item.$inMaintenance = null;
      }
      if (item.type === 'sia') {
        item.siaVersion = item.siaVersion || 'DC-09';
      }
    },
    oncall_groups(oncallGroup) {
      oncallGroup.$connected = oncallGroup.connector;
    },
    roles(role) {
      role.$isAdmin = window.EVA.enums.twsAdmins.includes(role.id);
    },
    sector_insiders(insider) {
      insider.$name = utils.name(insider.firstName, insider.lastName);
    },
    structure_logs(log) {
      var route = log.entryType;
      if (['user_groups', 'oncall_groups'].includes(route)) {
        // schema doesn't contain this column
        var prevUserIds = log.prevValue.users;
        log.prevValue = schema.normalize(log.prevValue, route);
        log.prevValue.userIds = prevUserIds;

        var newUserIds = log.newValue.users;
        log.newValue = schema.normalize(log.newValue, route);
        log.newValue.userIds = newUserIds;
      } else {
        log.prevValue = schema.normalize(log.prevValue, route);
        log.newValue = schema.normalize(log.newValue, route);
      }
    },
    time_plans(timePlan) {
      if (timePlan.startDate) {
        timePlan.$startDate = shiftLocationZone(timePlan.startDate * 1000);
      }

      if (timePlan.endDate) {
        timePlan.$endDate = shiftLocationZone(timePlan.endDate * 1000);
      }

      if (timePlan.hourPlans) {
        timePlan.hourPlans.forEach(function (hourPlan) {
          hourPlan.$from = toMinutes(hourPlan.from);
          hourPlan.$to = toMinutes(hourPlan.to);
        });
      }
    },
    user_groups(userGroup) {
      if (userGroup.connectorLimits && userGroup.connectorLimits.namespaces === null) {
        userGroup.connectorLimits.namespaces = [];
      }

      userGroup.$connector = !userGroup.isRemote && userGroup.connectorType == 'local';
      userGroup.$connected = !userGroup.isRemote && userGroup.connectorType == 'remote';
    },
    visitors(visitor) {
      if (visitor.startTime === null) {
        visitor.$startTime = null;
      } else {
        visitor.$startTime = visitor.startTime * 1000;
      }
      visitor.$permanent = visitor.type === 'permanent';
      visitor.$name = utils.name(visitor.firstName, visitor.lastName);
      visitor.$expiredAt = shiftLocationZone(visitor.expiredAt);
      visitor.$briefingAt = shiftLocationZone(visitor.briefingAt);
      visitor.$ndaAt = shiftLocationZone(visitor.ndaAt);
      visitor.$canCheckIn = !!(
        visitor.plannedEndTime && visitor.buildingSectorId && visitor.companyContact &&
        visitor.$briefingAt && (visitor.type !== 'permanent' || visitor.expiredAt && (Date.now() < visitor.$expiredAt))
      );
      visitor.$canBeReset = !visitor.checkedIn && !visitor.$permanent && (visitor.cardNumber || visitor.extId);
    }
  });
}
